import React, { useState, useEffect } from "react";
import axios from "axios";

const Awrapper = () => {
  const [data, setData] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get("https://buzcatch.pythonanywhere.com/api/homepage/");
        setData(response.data.count_sections[0]); // Assuming you want the first section
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  if (!data) {
    return <p>Loading...</p>;
  }

  return (
    <>
      <section className='awrapper'>
        <div className='container grid'>
          <div className='box flex'>
            <div className='img'>
              {/* Replace with appropriate image */}
              <img src="https://img.icons8.com/external-yogi-aprelliyanto-basic-outline-yogi-aprelliyanto/80/ffffff/external-graduation-education-yogi-aprelliyanto-basic-outline-yogi-aprelliyanto.png" alt='Visual Representation' />
            </div>
            <div className='text'>
              <h1>{data.students}</h1>
              <h3>STUDENTS</h3>
            </div>
          </div>
          <div className='box flex'>
            <div className='img'>
              {/* Replace with appropriate image */}
              <img src="https://img.icons8.com/ios/80/ffffff/athlete.png" alt='Visual Representation' />
            </div>
            <div className='text'>
              <h1>{data.trusted_tutors}</h1>
              <h3> TRUSTED TUTORS </h3>
            </div>
          </div>
          <div className='box flex'>
            <div className='img'>
              {/* Replace with appropriate image */}
              <img src="https://img.icons8.com/external-outline-icons-maxicons/80/ffffff/external-calender-insurance-outline-outline-icons-maxicons.png" alt='Visual Representation' />
            </div>
            <div className='text'>
              <h1>{data.schedules}</h1>
              <h3>SCHEDULES</h3>
            </div>
          </div>
          <div className='box flex'>
            <div className='img'>
              {/* Replace with appropriate image */}
              <img src="https://img.icons8.com/ios/80/ffffff/macbook-idea--v3.png" alt='Visual Representation' />
            </div>
            <div className='text'>
              <h1>{data.tropies}</h1>
              <h3>TROPIES</h3>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Awrapper;
