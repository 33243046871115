import React from "react";
import "./heading.css";

const Heading = ({ subtitle, title, footer }) => {
  return (
    <>
      <div className="welcome-heading">
        <h2>{subtitle} </h2>
        <div>
          <h1>" {title} " </h1>
          <p>{footer}</p>
        </div>
      </div>
    </>
  );
};

export default Heading;
