import React, { useState, useEffect } from "react";
import Heading from "../../common/heading/Heading";
import "./Hero.css";
import axios from "axios";

const Hero = () => {
  const [data, setData] = useState(null);
  console.log(data);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          "https://buzcatch.pythonanywhere.com/api/homepage/"
        );
        setData(response.data.banner_sections[0]); // Assuming you want the first section
        console.log("Data fetched:", response.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  if (!data) {
    return <p>Loading...</p>;
  }

  return (
    <>
      <section className="hero">
        <div className="container">
          <div className="row">
            <Heading
              subtitle={data.content_one}
              title={data.content_two}
              footer={data.content_three}
            />
            <div className="button">
              <button className="primary-btn">
                GET STARTED NOW <i className="fa fa-long-arrow-alt-right"></i>
              </button>
            </div>
          </div>
        </div>
      </section>
      <div className="margin1"></div>
    </>
  );
};

export default Hero;
