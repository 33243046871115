import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Masonry from 'react-masonry-css';
import './Gallery.css'; // Import a CSS file for styling

const Gallery = () => {
  const [images, setImages] = useState([]);

  useEffect(() => {
    const fetchImages = async () => {
      try {
        const response = await axios.get('https://buzcatch.pythonanywhere.com/api/academic/');
        setImages(response.data);
      } catch (error) {
        console.error('Error fetching images:', error);
      }
    };

    fetchImages();
  }, []);

  const breakpointColumnsObj = {
    default: 4,
    1100: 3,
    700: 2,
    500: 1
  };

  return (
    <Masonry
      breakpointCols={breakpointColumnsObj}
      className="my-masonry-grid"
      columnClassName="my-masonry-grid_column"
    >
      {images.map(image => (
        <div key={image.id}>
          <img src={`https://buzcatch.pythonanywhere.com${image.image}`} alt={`Gallery ${image.id}`} style={{height:"auto"}}/>
        </div>
      ))}
    </Masonry>
  );
};

export default Gallery;
