import React, { useContext } from "react";
import "../blog/blog.css";
import { BlogContext } from "../blog//BlogContext";
import Heading from "../common/heading/Heading";
import { Link } from 'react-router-dom';

const Hblog = () => {
  const { blogs, loading, error } = useContext(BlogContext);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <>
      <section className='blog'>
        <div className='container'>
          <Heading subtitle='OUR BLOG' title='Recent From Blog' />
          <div className='grid2 grid1'>
            {blogs.slice(0, 3).map((val) => (
              <div className='items shadow' key={val.id}>
                <div className='img'>
                  <img src={`https://buzcatch.pythonanywhere.com${val.blog_image}`} alt={val.blog_title} loading="lazy" />
                </div>
                <div className='text'>
                  <div className='admin flexSB'>
                    <span>
                      <i className='fa fa-user'></i>
                      <label>{val.type || "Admin"}</label>
                    </span>
                    <span>
                      <i className='fa fa-calendar-alt'></i>
                      <label>{new Date(val.posted_date).toLocaleDateString()}</label>
                    </span>
                  </div>
                  <h1>{val.blog_title}</h1>
                  <p>{val.blog_content}</p>
                  <p><Link to={`/blog/${val.id}`}>read more...</Link></p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    </>
  );
};

export default Hblog;
