import React, { useEffect, useState } from 'react';
import './v.css'; // Import the CSS file
import Back from "../common/back/Back"


const VlogItem = ({ title, imageUrl, date, youtubeUrl }) => {
    return (
        <div className="vlog-item">
            <img
                src={imageUrl}
                alt={title}
                onClick={() => window.open(youtubeUrl, '_blank')}
            /> <br />
            <h2>{title}</h2>
            <p>Childrens Day Celebration</p>
            <p>Posted date: {date}</p>
        </div>
    );
};

const VlogList = () => {
    const [vlogs, setVlogs] = useState([]);

    useEffect(() => {
        fetch('https://buzcatch.pythonanywhere.com/api/vlogs/')
            .then(response => response.json())
            .then(data => setVlogs(data))
            .catch(error => console.error('Error fetching vlogs:', error));
    }, []);

    return (
        <div>

            <Back title='Vlog Posts' />

            <div className="vlog-list">

                {vlogs.map(vlog => (
                    <VlogItem
                        key={vlog.id}
                        imageUrl={`https://buzcatch.pythonanywhere.com${vlog.image}`}
                        title={vlog.title}
                        date={vlog.posted_date}
                        youtubeUrl={vlog.youtube_link}
                    />
                ))}
            </div>
        </div>
    );
};

export default VlogList;
