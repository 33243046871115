import React from "react";
import imglogo from "../../../img/logo-removebg-preview (1).png";

const logoStyle = {
  width: window.innerWidth <= 768 ? "80px" : "140px",
  // backgroundColor: "white",
  // borderRadius: "50%",
  // padding: window.innerWidth <= 768 ? "0.6rem 0rem" : "1.2rem 0rem",
  transition: "all 0.3s ease",
};

const Head = () => {
  return (
    <>
      <section className="head">
        <div className=" container-head-47">
          <div className="logo">
            {/* <h1>Vimalagiri</h1>
            <span>International School</span> */}
            <img src={imglogo} style={logoStyle} alt="vimalagiri" />
          </div>
          <div className="brand-container">
            <h1 className="brand-name">Vimalagiri</h1>
            <h2>International School, Muvattupuzha</h2>
            <p>(Affiliated to CISCE New Delhi Aff.No.KE143)</p>
          </div>
        </div>
      </section>
    </>
  );
};

export default Head;
