import React from "react"
import Hblog from "./Hblog"
// import HAbout from "./HAbout"
import Hero from "./hero/Hero"
import About from "./Ab"
// import Hprice from "./Hprice"
// import Testimonal from "./testimonal/Testimonal"
import { BlogProvider } from "../blog/BlogContext";

const Home = () => {
  return (
    <>
      <Hero />
      <About />
      {/* <HAbout /> */}
      {/* <Testimonal /> */}
      <BlogProvider>
        <Hblog />
      </BlogProvider>
      {/* <Hprice /> */}
    </>
  )
}

export default Home
