import React, { useContext } from "react"
import Back from "../common/back/Back"
import "./contact.css"
import { ContactContext } from "./ContactContext"
import ContactForm from "./Contactform"

const Contact = () => {

  const { contacts, loading, error } = useContext(ContactContext)

  const map = 'https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d31434.096731885475!2d76.5712!3d9.995196!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3b07e75d5cba4e91%3A0xe2725dc6d59efbd3!2sVimalagiri%20International%20School%20(ICSE%20Syllabus)!5e0!3m2!1sen!2sin!4v1722572848971!5m2!1sen!2sin" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>" '
  return (
    <>
      <Back title='Contact us' />
      <section className='contacts padding'>
        <div className='container shadow flexSB'>
          <div className='left row'>
            <iframe src={map}></iframe>
          </div>


          <div className='right row'>
            <h1>Contact us</h1>
            <p>We're open for any suggestion or just to have a chat</p>
            {contacts.map((val) => (
              <div className='items grid2 grid1'>
                <div className='box'>
                  <h4>ADDRESS:</h4>
                  <p>{val.address}</p>
                </div>
                <div className='box'>
                  <h4>EMAIL:</h4>
                  <p>{val.email}</p>
                </div>
                <div className='box'>
                  <h4>PHONE:</h4><br />
                  <h4>{val.contact_name_one}</h4>
                  <p>{val.phone_number_one}</p>
                  <h4>{val.contact_name_two}</h4>
                  <p>{val.phone_number_two}</p>
                  <h4>{val.contact_name_three}</h4>
                  <p>{val.phone_number_three}</p>
                </div>
              </div>
            ))}

           <ContactForm />

          </div>
        </div>
      </section>
    </>
  )
}

export default Contact
