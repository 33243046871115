import "./App.css";
import Header from "./components/common/header/Header";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import About from "./components/about/About";
import Team from "./components/team/Team";
import Blog from "./components/blog/Blog";
import Contact from "./components/contact/Contact";
import Footer from "./components/common/footer/Footer";
import Home from "./components/home/Home";
import { ContactProvider } from "./components/contact/ContactContext";
import { BlogProvider } from "./components/blog/BlogContext";
import BlogCard from "./components/blog/BlogCard";
import BlogLayout from "./components/blog/Indi_blog";
import VlogList from "./components/vlog/Vlog";
import Gallery from "./components/team/Academic";
import Galleryy from "./components/team/Galleryy";
import A from "./components/team/A";
import B from "./components/team/B";
import C from "./components/Facility/C";


// import TestComponent from "./components/Test";

function App() {

  const ContactWrapper = () => (
    <ContactProvider>
      <Contact />
    </ContactProvider>
  );

  const FooterWrapper = () => (
    <BlogProvider>
      <ContactProvider>
        <Footer />
      </ContactProvider>
    </BlogProvider>
  );

  const BlogWrapper = () => (
    <BlogProvider>
      <BlogLayout />
    </BlogProvider>
  );


  return (
    <>
      <Router basename="/" >
        {/* basename="/school" */}
        <Header />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          {/* <Route path="/courses" element={<CourseHome />} /> */}
          <Route path="/gallery" element={<Team />} />
          {/* <Route path="/pricing" element={<Pricing />} /> */}
          <Route path="/news" element={<Blog />} />
          <Route path="/vlog" element={<VlogList />} />
          <Route path="/facility" element={<VlogList />} />

          <Route path="/gallery/type1" element={<A />} />
          <Route path="/gallery/type2" element={<B />} />
          <Route path="/gallery/type3" element={<C />} />




          <Route path="/contact" element={<ContactWrapper />} />
          {/* <Route path="/test" element={<TestComponent />} /> */}

          <Route path="/blog/:id" element={<BlogWrapper />} />
        </Routes>
        <FooterWrapper />
      </Router>
    </>
  );
}

export default App;
