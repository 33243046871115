import React from "react"
import Back from "../common/back/Back"
import Galleryy from "./Galleryy"


const Blog = () => {
  return (
    <>
      <Back title='Recreation' />
      <section className='blog '>
        <div className='container '>
         <Galleryy />

        </div>
      </section>
    </>
  )
}

export default Blog
