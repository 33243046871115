import React from "react"
import Back from "../common/back/Back"
import Facility from "./Facility"

const A = () => {
    return (
        <>
            <Back title='Academic' />
            <section className='blog '>
                <div className='container '>

                    <Facility />

                </div>
            </section>
        </>
    )
}

export default A
