import React from "react"
import Back from "../common/back/Back"
import BlogCard from "./BlogCard"
import "./blog.css"
import { BlogProvider } from "./BlogContext";

const Blog = () => {
  return (
    <>
      <Back title='Blog Posts' />
      <section className='blog padding'>
        <div className='container grid2 grid1'>
          <BlogProvider>
            <BlogCard />
          </BlogProvider>

        </div>
      </section>
    </>
  )
}

export default Blog
