import React from "react"
import Back from "../common/back/Back"
import TeamCard from "./TeamCard"
import "./team.css"
import Awrapper from "../about/Awrapper"
import "../about/about.css"
import ImageGallery from "./Img"

const Team = () => {
  return (
    <>
      <Back title='Gallery' />
      <section className='team padding'>
        <div className='container '>
          {/* <TeamCard /> */}
          <div style={{display:"block !important"}}>
            <ImageGallery />
          </div>

        </div>
      </section>
      <Awrapper />
    </>
  )
}

export default Team
