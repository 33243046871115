import React, { useEffect, useState } from "react"
import Heading from "../common/heading/Heading"
import "./about.css"
import Awrapper from "./Awrapper"
import TeamSection from "./Team"
import Img from "../../img/i/2.jpg"
import axios from "axios"

const AboutCard = () => {
  const [imageUrl, setImageUrl] = useState('');

  useEffect(() => {
    axios.get('https://buzcatch.pythonanywhere.com/api/aboutus/')
      .then(response => {
        const image = response.data.aboutus_section[0].image;
        setImageUrl(`https://buzcatch.pythonanywhere.com${image}`);
      })
      .catch(error => {
        console.error('There was an error fetching the image!', error);
      });
  }, []);
  return (
    <>
      <section className='aboutHome'>
        <div className='container flexSB'>
          <div className='left row'>

            <img src={Img} alt='' />
          </div>
          <div className='right row' style={{ paddingTop: '35px' }}>
            <Heading subtitle='A Brief History' title='Excellence Through Education' />
            <div className='text' style={{ color: "grey" }}>
              <br />
              <p style={{ textAlign: "justify" }}>
                Vimalagiri International School at Muvattupuzha is under the trust, namely Muvattupuzha Vimalagiri International Educational and Charitable Trust. This school comes under the Eparchy of Muvattupuzha of Syro-Malankara Catholic Church, Diocese of Muvattupuzha.
              </p> <br />
              <p style={{ textAlign: "justify" }}>
                Even though the diocese is having many institutions not only in Kerala state, but also in various states of India, it desired very much to have an educational institution in the town of Muvattupuzha with a different perspective.
              </p><br />
              <p style={{ textAlign: "justify" }}>
                So, then the Bishop of the Diocese of Muvattupuzha, Most Rev. Dr. Thomas Mar Koorilos entrusted the work of establishing a new school with Rt. Rev. Corepiscopo John Varghese Easwarankudiyil. So the foundation stone of the school was laid in the year 2004. The construction work of the school consistently progressed till it became a fully-fledged school with the recognition of the council in New Delhi. It was all painstakingly planned under the relentless work of Rt. Rev. Corepiscopo John Varghese Easwarankudiyil as the founder Manager of the school.
              </p><br />
              <p style={{ textAlign: "justify" }}>
                Initially the play class began with an old double story building closer to the construction area of the school. In the beginning there were only a handful number of pupils, but as the construction completed the number of students increased and they were shifted to the new building of the school.
              </p><br />
              <p style={{ textAlign: "justify" }}>
                The first Principal of the school was Sr. Mareena OSS. Then later Mrs. Rajeshwari Jimmy took charge as the Principal and under her leadership the school grew up to 4th grade. Then later Mrs. Rose Jonny became the Principal. During her period the school grew up to 8th grade and thereafter Mr. Jison K Abraham became the Principal and during his time the school was affiliated to CISCE, New Delhi (Affiliation No. KE 143).
              </p><br />
              {/* <p style={{ textAlign: "justify" }}>
                The first batch of the class ten in Indian Certificate of Secondary Education (ICSE) wrote examination in the year of 2015 and they came out with an outstanding result. In the year, 2019-2020 was our 5th batch of ICSE examination. Our school consistently produces the exemplary result every year. The Management, the Parents and all the well-wishers are much pleased with the functioning of Vimalagiri International School. Within a short span of time, the school has got a good reputation and honour among the people.
              </p><br /> */}
            </div>
          </div>


        </div>
        <div className='left'>
          {imageUrl && (
            <img
              src={imageUrl}
              alt='About Us'
              style={{ padding: '0px 120px 0px', height: '560px' }}
            />
          )}
        </div>
      </section>

      <TeamSection />

      <Awrapper />
    </>
  )
}

export default AboutCard
