import React, { useState } from 'react';
import axios from 'axios';

const Newsletter = () => {
    const [email, setEmail] = useState('');
    const [newsletterImage, setNewsletterImage] = useState('');

    const handleSubmit = async (event) => {
        event.preventDefault();
        try {
            const postResponse = await axios.post('https://buzcatch.pythonanywhere.com/api/homepage/', { email });
            console.log('POST Response:', postResponse);

            if (postResponse.status === 200) {
                alert('Newsletter subscription successful!');
            } else {
                alert('Failed to subscribe to the newsletter.');
            }
        } catch (error) {
            console.error('Error:', error);
            alert('Error occurred while subscribing.');
        }
    };

    const fetchNewsletterImage = async () => {
        try {
            const getResponse = await axios.get('https://buzcatch.pythonanywhere.com/api/homepage/');
            console.log('GET Response:', getResponse);

            if (getResponse.status === 200) {
                const { newsletter } = getResponse.data;
                if (newsletter && newsletter.length > 0) {
                    setNewsletterImage(newsletter[0].newsletter);
                }
            }
        } catch (error) {
            console.error('Error:', error);
        }
    };

    React.useEffect(() => {
        fetchNewsletterImage();
    }, []);

    return (
        <div className='container flexSB flexSB1'>
            <div className='left row row1 pdr'>
                <h1>Newsletter - Stay tuned and get the latest update</h1>
                <span>Far far away, behind the word mountains</span>
            </div>
            <div className=' row row1'>
                <form onSubmit={handleSubmit} className='right'>
                    <input
                        type='email'
                        placeholder='Enter email address'
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                    />
                    <button type='submit' style={{padding:'2px 0px',margin:'0px 0px 0px 20px'}}>
                        <i className='fa fa-paper-plane' style={{marginLeft:"0px"}}></i>
                    </button>
                </form>
                {newsletterImage && <img src={`https://buzcatch.pythonanywhere.com${newsletterImage}`} alt='Newsletter' />}
            </div>
        </div>
    );
};

export default Newsletter;
