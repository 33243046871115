import React from "react"
import Heading from "../common/heading/Heading"
import "../about/about.css"
import { homeAbout } from "../../dummydata"
import Awrapper from "../about/Awrapper"
import Abimg from "../../img/i/1.jpg"
import {Link} from "react-router-dom"

const About = () => {
  return (
    <>
      <section className='aboutHome'>
        <div className='container flexSB '>
          <div className='left row'>
            <img src={Abimg} alt='' />
          </div>
          <div className='right row'>
            <Heading subtitle='A Family Of Learning' title='Excellence Through Education' />
            <div className='items'>
              {homeAbout.slice(0, 3).map((val) => {
                return (
                  <div className='item flexSB flexSB1'>
                    <div className='img'>
                      <img src={val.cover} alt='' />
                    </div>
                    <div className='text'>
                      <h2>{val.title}</h2>
                      <p>{val.desc}</p>
                      {/* <a  style={{color:'#6868d5',textDecoration:'underline'}}>Read more..</a> */}
                      {/* <Link>Read more..</Link> */}
                    </div>
                  </div>
                )
              })}
            </div>
          </div>
        </div>
      </section>
      <Awrapper />
    </>
  )
}

export default About
