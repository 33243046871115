import React, { useState } from "react";
import { Link } from "react-router-dom";
import Head from "./Head";
import "./header.css";
import { Widgets } from "@mui/icons-material";

const Header = () => {
  const [click, setClick] = useState(false);

  const [isOpen, setIsOpen] = useState(false);

  const handleMouseEnter = () => setIsOpen(true);
  const handleMouseLeave = () => setIsOpen(false);

  const dropdownStyles = {
    position: "relative",
    display: "inline-block",
  };

  const linkStyles = {
    textDecoration: "none",
    color: "#fff",
    borderRadius: "5px",
    display: "inline-block",
    fontSize: "16px",
    transition: "background-color 0.3s ease",
  };

  const dropdownMenuStyles = {
    display: isOpen ? "block" : "none",
    position: "absolute",
    top: "100%",
    left: "0",
    backgroundColor: "#fff",
    listStyle: "none",
    padding: "0",
    margin: "0",
    boxShadow: "0 8px 16px rgba(0, 0, 0, 0.2)",
    zIndex: "1",
    minWidth: "200px",
    borderRadius: "5px",
    overflow: "hidden",
  };

  const dropdownItemStyles = {
    padding: "10px 20px",
    textDecoration: "none",
    color: "#333",
    display: "block",
    transition: "background-color 0.3s ease",
  };

  const dropdownItemHoverStyles = {
    backgroundColor: "#97022c",
    color: "white",
  };

  const iconStyles = {
    marginRight: "10px",
  };

  const handleMouseOver = (e) => {
    e.currentTarget.style.backgroundColor =
      dropdownItemHoverStyles.backgroundColor;
    e.currentTarget.style.color = dropdownItemHoverStyles.color;
  };

  const handleMouseOut = (e) => {
    e.currentTarget.style.backgroundColor = "transparent";
    e.currentTarget.style.color = "#333";
  };

  const handleTouchStart = () => {
    setIsOpen(!isOpen);
  };
  return (
    <>
      <Head />
      <header>
        <nav className="flexSB">
          <ul
            className={click ? "mobile-nav" : "flexSB "}
            onClick={() => setClick(false)}
          >
            <li>
              <Link to="/">Home</Link>
            </li>
            {/* <li>
              <Link to='/courses'>All Courses</Link>
            </li> */}
            <li>
              <Link to="/about">About</Link>
            </li>

            {/* <li>
              <Link to='/pricing'>Pricing</Link>
            </li> */}
            <li>
              <Link to="/news">News</Link>
            </li>
            <li
              onMouseEnter={() => setIsOpen(true)}
              onMouseLeave={() => setIsOpen(false)}
              onTouchStart={handleTouchStart}
              style={dropdownStyles}
            >
              <Link to="#" style={linkStyles}>
                Gallery
              </Link>
              <ul style={dropdownMenuStyles}>
                <li style={{ width: "100%" }}>
                  <Link
                    to="/gallery/type1"
                    style={dropdownItemStyles}
                    onMouseOver={handleMouseOver}
                    onMouseOut={handleMouseOut}
                  >
                    <i className="fas fa-book" style={iconStyles}></i> Academic
                  </Link>
                </li>
                <li style={{ width: "100%" }}>
                  <Link
                    to="/gallery/type2"
                    style={dropdownItemStyles}
                    onMouseOver={handleMouseOver}
                    onMouseOut={handleMouseOut}
                  >
                    <i className="fas fa-futbol" style={iconStyles}></i>{" "}
                    Recreation
                  </Link>
                </li>
                <li style={{ width: "100%" }}>
                  <Link
                    to="/gallery/type3"
                    style={dropdownItemStyles}
                    onMouseOver={handleMouseOver}
                    onMouseOut={handleMouseOut}
                  >
                    <i className="fas fa-building" style={iconStyles}></i>{" "}
                    Facility
                  </Link>
                </li>
              </ul>
            </li>
            <li>
              <Link to="/vlog">Vlog</Link>
            </li>
            <li>
              <Link to="/contact">Contact</Link>
            </li>
          </ul>
          <div className="start">
            <div
              className="button"
              style={{ cursor: "pointer" }}
              onClick={() =>
                (window.location.href =
                  "https://buzatbook.com/vimalagiri/login")
              }
            >
              Login
            </div>
          </div>

          <button className="toggle" onClick={() => setClick(!click)}>
            {click ? (
              <i className="fa fa-times"> </i>
            ) : (
              <i className="fa fa-bars"></i>
            )}
          </button>
        </nav>
      </header>
    </>
  );
};

export default Header;
